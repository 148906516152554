var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"form_mark_upload",staticClass:"repeater-form",attrs:{"method":"post","action":"javascript:void(0)"},on:{"submit":_vm.saveMarkSubmissionRecord}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Exam type"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('exam type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.exam_type_id),expression:"exam_type_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"exam_type_id","id":"exam_type_id","state":errors.length > 0 ? false : null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.exam_type_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.isMarkAlreadySumitted()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Exam Type")]),_vm._l((_vm.exmTypes),function(eType,key){return _c('option',{key:key,domProps:{"value":eType.id}},[_vm._v(" "+_vm._s(eType.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Wings"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('wings'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.wing_id),expression:"wing_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"wing_id","id":"wing_id","state":errors.length > 0 ? false : null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.wing_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.isMarkAlreadySumitted()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Wing")]),_vm._l((_vm.wings),function(wing,key){return _c('option',{key:key,domProps:{"value":wing.id}},[_vm._v(" "+_vm._s(wing.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Groups"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('wings'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.student_group_id),expression:"student_group_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"student_group_id","id":"student_group_id","state":errors.length > 0 ? false : null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.student_group_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.loadUploadedMark(); _vm.isMarkAlreadySumitted();}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Group")]),_vm._l((_vm.student_groups),function(group,key){return _c('option',{key:key,domProps:{"value":group.id}},[_vm._v(" "+_vm._s(group.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Subject"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('subject'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{staticClass:"form-control",attrs:{"id":"subject_id","disabled":"","state":errors.length > 0 ? false : null}},[_c('option',{domProps:{"value":_vm.subject}},[_vm._v(" "+_vm._s(_vm.subjectByID(_vm.subject))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.exam_type_id)?[_c('b-row',[[_c('b-col',{attrs:{"md":"2"}},[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Cadet No"))+" ")])])],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Student Name"))+" ")])])],1),_vm._l((_vm.mkDistributions),function(mark_distribution,index){return _c('b-col',{key:index,attrs:{"md":"2"}},[[_vm._v(" "+_vm._s(mark_distribution.name)+" ("+_vm._s((mark_distribution.total_mark))+") ")]],2)}),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])]],2),_vm._l((_vm.studentList),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id}},[(_vm.isSubjectTaken(item, _vm.mkDistributions))?[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('student_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":item.userable.cadet_no,"readonly":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('cadet no')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('student_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":item.userable.name,"readonly":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('student name')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._l((_vm.mkDistributions),function(mark_distribution,index){return _c('b-col',{key:index,ref:"row2",refInFor:true,attrs:{"md":"2"}},[[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mark'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":mark_distribution.id + '-' + Math.floor(Date.now()),"value":_vm.getUploadedMark(mark_distribution, item),"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('mark')},on:{"change":function($event){_vm.onObtainedMarkChanged(index, $event); _vm.saveChangedMark($event, item, mark_distribution.id)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]],2)}),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])]:_vm._e()],2)})]:_vm._e(),_c('b-row',{ref:"row2",attrs:{"id":"button_row"}},[[_c('b-col',{staticClass:"mt-2 pt-1 col text-right",attrs:{"md":"2"}},[_c('b-button',{attrs:{"id":"btn_save","value":"save","variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1),_c('b-col',{staticClass:"mt-2 pt-1 col text-center",attrs:{"md":"2"}},[_c('b-button',{attrs:{"id":"btn_submit","value":"submit","variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")])],1),_c('b-col',{staticClass:"mt-2 pt-1 col text-left",attrs:{"md":"2"}},[_c('b-button',{attrs:{"id":"btn_print","value":"submit","variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Print'))+" ")])],1)]],2)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }